import React from 'react';
import { Switch, Route } from 'react-router';

import Landing from './landing';
import Contact from './contact';
import Projects from './projects';
import Resume from './resume';

const Main = () => (
    <Switch>
        <Route path="/landing" component={Landing} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/projects" component={Projects} />
        <Route exact path="/resume" component={Resume} />
        <Route path="/landing" component={() => {
            window.location.goBack();
            return null;
        }}/>
        <Route path="/contact" component={() => {
            window.location.goBack();
            return null;
        }}/>
        <Route path="/projects" component={() => {
            window.location.goBack();
            return null;
        }}/>
        <Route path="/resume" component={() => {
            window.location.goBack();
            return null;
        }}/>
        <Route component={Landing} />
    </Switch>
)
export default Main;