import React, { Component } from 'react';
import { Grid, Cell, Card, CardTitle, CardText, CardActions, Button } from 'react-mdl';

//import alexa from '../assets/alexa.jpg';
//import patent from '../assets/patent.png';
//import stellar from '../assets/stellar.png';
import Test from '../assets/VaxTrack-Privacy_Policy.pdf';
import eVASPolicy from '../assets/eVAS-Privacy_Policy.pdf';
import web from '../assets/web.jpg';
import web2 from '../assets/web2.jpg';
import stella_text from '../assets/smart_speaker2.jpg';
import watchMfp1 from '../assets/fitness_mfp1.jpg'
import watchMfp2 from '../assets/fitness_mfp2.jpg'
import watchMfp3 from '../assets/fitness_mfp3.jpg'
import watch1 from '../assets/watch1.jpg';
import watch2 from '../assets/watch2.jpg';
import watch3 from '../assets/watch3.jpg';
//import watch4 from '../assets/watch4.png';
import watch5 from '../assets/watch5.jpg';
import lfp01 from '../assets/lfp01.jpg';
import lfp02 from '../assets/lfp02.jpg';
//import lfp03 from '../assets/lfp03.png';
//import lfp1 from '../assets/lfp1.jpeg';
//import lfp2 from '../assets/lfp2.jpeg';
//import lfp3 from '../assets/lfp3.jpeg';
import mfp1 from '../assets/mfp01.jpg';
import mfp2 from '../assets/mfp2.jpg';
import mfp3 from '../assets/mfp03.jpg';
import hfp1 from '../assets/hfp1.jpg';
import hfp2 from '../assets/hfp2.jpg';
import hfp3 from '../assets/hfp3.jpg';
import hfp4 from '../assets/hfp4.jpg';
//import vaxtrack from '../assets/vaxtrack1-1.jpg';
//import vt2 from '../assets/vaxtrack2-1.jpg';
//import vt3 from '../assets/vax-screenshots-1.jpg';
//import vt4 from '../assets/vax-screenshots-2.jpg';
import vt5 from '../assets/Vax-Track-2.jpg';
import vt6 from '../assets/Vax-Track-1.jpg';
import vt7 from '../assets/vax-track-icon.jpg';
import vt01 from '../assets/vt01.jpg';
import vt02 from '../assets/vt02.jpg';
import vt03 from '../assets/vt03.jpg';
import vt04 from '../assets/vt04.jpg';

//import evas1 from '../assets/evas-icon.jpg';
import evas01 from '../assets/evas01.jpg';
import evas02 from '../assets/evas02.jpg';
import evas03 from '../assets/evas03.jpg';
import evas04 from '../assets/evas04.jpg';
import evas05 from '../assets/evas05.jpg';
import evas06 from '../assets/evas-new-icon.jpg';
import evasQR from '../assets/evas-QR.jpg';

//import air purifier
import air_purifier1 from '../assets/air_purifier1.jpg';
import air_purifier3 from '../assets/air_purifier3.jpg';
import air_purifier4 from '../assets/new-filter-box.jpg';
import bottom from '../assets/Bottom.dwg';
import top from '../assets/Top.dwg';
import left from '../assets/Left.dwg';
import right from '../assets/Right.dwg';
import old_bottom from '../assets/Old_Bottom.dwg';
import old_top from '../assets/Old_Top.dwg';
import old_left from '../assets/Old_Left.dwg';
import old_right from '../assets/Old_Right.dwg';
import assembly from '../assets/Assembly_Instructions_Aug-28-2023.pdf';

import bottom_p14_acrylic from '../assets/BOTTOM-P14-open-3mm.dwg';
import top_p14_acrylic from '../assets/TOP-P14-open-3mm.dwg';
import left_p14_acrylic from '../assets/LEFT-P14-3mm.dwg';
import right_p14_acrylic from '../assets/RIGHT-P14-3mm.dwg';
import corner_p14_acrylic from '../assets/Corner-Piece-P14.dwg';

import bottom32_p14_acrylic from '../assets/BOTTOM-P14-3-2mm-open.dwg';
import top32_p14_acrylic from '../assets/TOP-P14-3-2mm-open.dwg';
import left32_p14_acrylic from '../assets/LEFT-P14-3-2mm.dwg';
import right32_p14_acrylic from '../assets/RIGHT-P14-3-2mm.dwg';
import corner32_p14_acrylic from '../assets/Corner-Piece-P14-3-2mm.dwg';

import fitness_happ from '../assets/fitness_happ.jpg';
import stellar_care from '../assets/stellar_care.jpg';
import virtual_assistant from '../assets/virtual_assistant.jpg';
//import catheter from '../assets/catheter.jpg';
import website from '../assets/website.jpg';

import { motion } from 'framer-motion';

class Projects extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            activeTab: 0,
            card1: 'Learn More',
            card2: 'Learn More',
            card3: 'Learn More',
            card4: 'Learn More',
            card5: 'Learn More',
            card6: 'Learn More',
            card7: 'Learn More',

            va: virtual_assistant,
            card1_images: [virtual_assistant, stella_text],
            card1_counter: 0,

            web: website,
            card2_images: [website, web, web2],
            card2_counter: 0,

            watch: fitness_happ,
            card3_images: [fitness_happ, watchMfp1, watchMfp2, watchMfp3, watch1, watch2, watch3, watch5],
            card3_counter: 0,

            app: stellar_care,
            card4_images: [stellar_care, lfp01, lfp02, mfp1, mfp2, mfp3, hfp1, hfp2, hfp3, hfp4],
            card4_counter: 0,

            app2: vt6,
            card5_images: [vt6, vt01, vt02, vt03, vt04, vt7, vt5],
            card5_counter: 0,

            app3: evas06,
            card6_images: [evas06, evas01, evas05, evas02, evas03, evas04, evasQR],
            card6_counter: 0,

            air_purifier: air_purifier1,
            card7_images: [air_purifier1, air_purifier3, air_purifier4],
            card7_counter: 0,
         };
    }

    card1_image_next = () => {
        if (this.state.card1_counter+1 < this.state.card1_images.length){
            this.setState({
                va: this.state.card1_images[this.state.card1_counter+1],
                card1_counter: this.state.card1_counter+1,
            })
        }
        if (this.state.card1_counter+1 === this.state.card1_images.length){
            this.setState({
                va: this.state.card1_images[0],
                card1_counter: 0,
            })
        }
    }

    card1_image_prev = () => {
        if (this.state.card1_counter > 0){
            this.setState({
                va: this.state.card1_images[this.state.card1_counter-1],
                card1_counter: this.state.card1_counter-1,
            })
        }
        if (this.state.card1_counter === 0){
            this.setState({
                va: this.state.card1_images[this.state.card1_images.length-1],
                card1_counter: this.state.card1_images.length-1,
            })
        }
    }

    card2_image_next = () => {
        if (this.state.card2_counter+1 < this.state.card2_images.length){
            this.setState({
                web: this.state.card2_images[this.state.card2_counter+1],
                card2_counter: this.state.card2_counter+1,
            })
        }
        if (this.state.card2_counter+1 === this.state.card2_images.length){
            this.setState({
                web: this.state.card2_images[0],
                card2_counter: 0,
            })
        }
    }

    card2_image_prev = () => {
        if (this.state.card2_counter > 0){
            this.setState({
                web: this.state.card2_images[this.state.card2_counter-1],
                card2_counter: this.state.card2_counter-1,
            })
        }
        if (this.state.card2_counter === 0){
            this.setState({
                web: this.state.card2_images[this.state.card2_images.length-1],
                card2_counter: this.state.card2_images.length-1,
            })
        }
    }

    card3_image_next = () => {
        if (this.state.card3_counter+1 < this.state.card3_images.length){
            this.setState({
                watch: this.state.card3_images[this.state.card3_counter+1],
                card3_counter: this.state.card3_counter+1,
            })
        }
        if (this.state.card3_counter+1 === this.state.card3_images.length){
            this.setState({
                watch: this.state.card3_images[0],
                card3_counter: 0,
            })
        }
    }

    card3_image_prev = () => {
        if (this.state.card3_counter > 0){
            this.setState({
                watch: this.state.card3_images[this.state.card3_counter-1],
                card3_counter: this.state.card3_counter-1,
            })
        }
        if (this.state.card3_counter === 0){
            this.setState({
                watch: this.state.card3_images[this.state.card3_images.length-1],
                card3_counter: this.state.card3_images.length-1,
            })
        }
    }
    
    card4_image_next = () => {
        if (this.state.card4_counter+1 < this.state.card4_images.length){
            this.setState({
                app: this.state.card4_images[this.state.card4_counter+1],
                card4_counter: this.state.card4_counter+1,
            })
        }
        if (this.state.card4_counter+1 === this.state.card4_images.length){
            this.setState({
                app: this.state.card4_images[0],
                card4_counter: 0,
            })
        }
    }

    card4_image_prev = () => {
        if (this.state.card4_counter > 0){
            this.setState({
                app: this.state.card4_images[this.state.card4_counter-1],
                card4_counter: this.state.card4_counter-1,
            })
        }
        if (this.state.card4_counter === 0){
            this.setState({
                app: this.state.card4_images[this.state.card4_images.length-1],
                card4_counter: this.state.card4_images.length-1,
            })
        }
    }

    card5_image_next = () => {
        if (this.state.card5_counter+1 < this.state.card5_images.length){
            this.setState({
                app2: this.state.card5_images[this.state.card5_counter+1],
                card5_counter: this.state.card5_counter+1,
            })
        }
        if (this.state.card5_counter+1 === this.state.card5_images.length){
            this.setState({
                app2: this.state.card5_images[0],
                card5_counter: 0,
            })
        }
    }

    card5_image_prev = () => {
        if (this.state.card5_counter > 0){
            this.setState({
                app2: this.state.card5_images[this.state.card5_counter-1],
                card5_counter: this.state.card5_counter-1,
            })
        }
        if (this.state.card5_counter === 0){
            this.setState({
                app2: this.state.card5_images[this.state.card5_images.length-1],
                card5_counter: this.state.card5_images.length-1,
            })
        }
    }

    card6_image_next = () => {
        if (this.state.card6_counter+1 < this.state.card6_images.length){
            this.setState({
                app3: this.state.card6_images[this.state.card6_counter+1],
                card6_counter: this.state.card6_counter+1,
            })
        }
        if (this.state.card6_counter+1 === this.state.card6_images.length){
            this.setState({
                app3: this.state.card6_images[0],
                card6_counter: 0,
            })
        }
    }

    card6_image_prev = () => {
        if (this.state.card6_counter > 0){
            this.setState({
                app3: this.state.card6_images[this.state.card6_counter-1],
                card6_counter: this.state.card6_counter-1,
            })
        }
        if (this.state.card6_counter === 0){
            this.setState({
                app3: this.state.card6_images[this.state.card6_images.length-1],
                card6_counter: this.state.card6_images.length-1,
            })
        }
    }

    card7_image_next = () => {
        if (this.state.card7_counter+1 < this.state.card7_images.length){
            this.setState({
                air_purifier: this.state.card7_images[this.state.card7_counter+1],
                card7_counter: this.state.card7_counter+1,
            })
        }
        if (this.state.card7_counter+1 === this.state.card7_images.length){
            this.setState({
                air_purifier: this.state.card7_images[0],
                card7_counter: 0,
            })
        }
    }

    card7_image_prev = () => {
        if (this.state.card7_counter > 0){
            this.setState({
                air_purifier: this.state.card7_images[this.state.card7_counter-1],
                card7_counter: this.state.card7_counter-1,
            })
        }
        if (this.state.card7_counter === 0){
            this.setState({
                air_purifier: this.state.card7_images[this.state.card7_images.length-1],
                card7_counter: this.state.card7_images.length-1,
            })
        }
    }



    change_card1 = () => {
        
        if (this.state.card1 === 'Learn More') {
            this.setState({
                card1: 'See Less',
            });
        }
        
        else {
            this.setState({
                card1: 'Learn More',
            })
        }
    }

    change_card2 = () => {
        
        if (this.state.card2 === 'Learn More') {
            this.setState({
                card2: 'See Less',
            });
        }
        
        else {
            this.setState({
                card2: 'Learn More',
            })
        }
    }

    change_card3 = () => {
        
        if (this.state.card3 === 'Learn More') {
            this.setState({
                card3: 'See Less',
            });
        }
        
        else {
            this.setState({
                card3: 'Learn More',
            })
        }
    }

    change_card4 = () => {
        
        if (this.state.card4 === 'Learn More') {
            this.setState({
                card4: 'See Less',
            });
        }
        
        else {
            this.setState({
                card4: 'Learn More',
            })
        }
    }

    change_card5 = () => {
        
        if (this.state.card5 === 'Learn More') {
            this.setState({
                card5: 'See Less',
            });
        }
        
        else {
            this.setState({
                card5: 'Learn More',
            })
        }
    }

    change_card6 = () => {
        
        if (this.state.card6 === 'Learn More') {
            this.setState({
                card6: 'See Less',
            });
        }
        
        else {
            this.setState({
                card6: 'Learn More',
            })
        }
    }

    change_card7 = () => {
        
        if (this.state.card7 === 'Learn More') {
            this.setState({
                card7: 'See Less',
            });
        }
        
        else {
            this.setState({
                card7: 'Learn More',
            })
        }
    }

    air_purifier = () => {
        if (this.state.card7 === 'Learn More') {
            return(
                <CardText style={{textAlign: 'center'}}>
                   AutoCAD | Iterative Design | DIY Project
                </CardText>
            )
        }
        else {
            return(
                <CardText style={{textAlign: 'left', color: '#000'}}>
                    <p style = {{paddingBottom: 0, textAlign: 'center', fontWeight: 'bold'}}>
                        Last Updated: Friday, July 1st, 2024
                    </p>
                    
                    <p style = {{marginBottom: 10}}>
                        <p style = {{paddingBottom: 0, textAlign: 'justify'}}>
                        The harboard or black acrylic 6-Fan Air Purifier (click through images above) was originally configured using the FilterBoxBuilder.com website and then modified to remove the inner filter track and only have eight inner connection pieces.
                        </p>
                        <b>Materials List (6-Fan Design)</b>:
                        <ol style={{listStylePosition: 'outside', marginLeft: 0, paddingLeft: '20px', paddingTop: 0, marginTop: 0}}>
                            <li>
                                Top/Bottom/Left/Right Side Piece Templates (in mm): Get these cut from SendCutSend.com in the material of your choice. Be mindful of using the material thickness that aligns with the parts that you're downloading (e.g., if using a 3.2 mm thick hardboard, use the 3.2 mm parts)
                                <p style = {{paddingBottom: 0, paddingTop: 10, textAlign: 'justify'}}>
                                    Acrylic (3mm):
                                </p>
                                <ul>
                                    <li>
                                        <a style={{padding: '0px', color: '#2c3e50'}} href = {top_p14_acrylic} rel="noopener noreferrer" target = "_blank"><b>Download Top Piece AutoCAD Drawing (3mm - Acrylic)</b></a>
                                    </li>
                                    <li>
                                        <a style={{padding: '0px', color: '#2c3e50'}} href = {bottom_p14_acrylic} rel="noopener noreferrer" target = "_blank"><b>Download Bottom Piece AutoCAD Drawing (3mm - Acrylic)</b></a>
                                    </li>
                                    <li>
                                        <a style={{padding: '0px', color: '#2c3e50'}} href = {left_p14_acrylic} rel="noopener noreferrer" target = "_blank"><b>Download Left Piece AutoCAD Drawing (3mm - Acrylic)</b></a>
                                    </li>
                                    <li>
                                        <a style={{padding: '0px', color: '#2c3e50'}} href = {right_p14_acrylic} rel="noopener noreferrer" target = "_blank"><b>Download Right Piece AutoCAD Drawing (3mm - Acrylic)</b></a>
                                    </li>
                                    <li>
                                        <a style={{padding: '0px', color: '#2c3e50'}} href = {corner_p14_acrylic} rel="noopener noreferrer" target = "_blank"><b>Download Inner Corder Piece AutoCAD Drawing (3mm - Acrylic) - Cut 8 of these per box</b></a>
                                    </li>
                                </ul>
                                <p style = {{paddingBottom: 0, paddingTop: 10, textAlign: 'justify'}}>
                                    Hardboard (3.2mm):
                                </p>
                                <ul>
                                    <li>
                                        <a style={{padding: '0px', color: '#2c3e50'}} href = {top32_p14_acrylic} rel="noopener noreferrer" target = "_blank"><b>Download Top Piece AutoCAD Drawing (3.2mm - MDF/Hardboard)</b></a>
                                    </li>
                                    <li>
                                        <a style={{padding: '0px', color: '#2c3e50'}} href = {bottom32_p14_acrylic} rel="noopener noreferrer" target = "_blank"><b>Download Bottom Piece AutoCAD Drawing (3.2mm - MDF/Hardboard)</b></a>
                                    </li>
                                    <li>
                                        <a style={{padding: '0px', color: '#2c3e50'}} href = {left32_p14_acrylic} rel="noopener noreferrer" target = "_blank"><b>Download Left Piece AutoCAD Drawing (3.2mm - MDF/Hardboard)</b></a>
                                    </li>
                                    <li>
                                        <a style={{padding: '0px', color: '#2c3e50'}} href = {right32_p14_acrylic} rel="noopener noreferrer" target = "_blank"><b>Download Right Piece AutoCAD Drawing (3.2mm - MDF/Hardboard)</b></a>
                                    </li>
                                    <li>
                                        <a style={{padding: '0px', color: '#2c3e50'}} href = {corner32_p14_acrylic} rel="noopener noreferrer" target = "_blank"><b>Download Inner Corder Piece AutoCAD Drawing (3.2mm - MDF/Hardboard) - Cut 8 of these per box</b></a>
                                    </li>
                                </ul>
                                <p style = {{paddingBottom: 0, paddingTop: 10, textAlign: 'justify'}}>
                                    
                                </p>
                            </li>
                            <li>
                                <a style={{padding: '0px', color: '#2c3e50'}} href="https://www.amazon.ca/s?k=arctic+p14+fans&hvadid=657205298838&hvdev=c&hvlocphy=9192746&hvnetw=g&hvqmt=e&hvrand=6249443470246408494&hvtargid=kwd-1238365381847&hydadcr=24949_13591938&tag=googcana-20&ref=pd_sl_7pelj9kdpw_e" rel="noopener noreferrer" target="_blank"><b>6 Arctic P14 Fans (keep the screws)</b></a>
                            </li>
                            <li>
                                <a style={{padding: '0px', color: '#2c3e50'}} href="https://www.amazon.ca/s?k=140+mm+fan+grills&crid=1YD7ZWGCGH1DX&sprefix=140+mm+fan+grills%2Caps%2C111&ref=nb_sb_noss_2" rel="noopener noreferrer" target="_blank"><b>6 x 140 mm Protective Fan Grill</b></a> 
                            </li>
                            <li>
                                <a style={{padding: '0px', color: '#2c3e50'}} href="https://www.furnacefilterscanada.com/20x20x2-furnace-filter-merv-13-/" rel="noopener noreferrer" target="_blank"><b>2 x 20x20x2 Inch MERV-13 Filters</b></a>
                            </li>
                            <li>
                                <a style={{padding: '0px', color: '#2c3e50'}} href="https://www.amazon.ca/Listed-LEDMO-Adapter-100-240V-Transformers/dp/B06Y64QLBM?pd_rd_w=nt44s&content-id=amzn1.sym.520cbcec-61b2-4146-be71-5d74f7119e7c&pf_rd_p=520cbcec-61b2-4146-be71-5d74f7119e7c&pf_rd_r=5BPAN51DCP5RXB8MEJJJ&pd_rd_wg=YyQkY&pd_rd_r=7a4ccfcc-c4d0-4927-845c-9099038d7cc9&pd_rd_i=B06Y64QLBM&psc=1&ref_=pd_bap_d_grid_rp_0_1_ec_pr_pd_hp_d_atf_rp_1_i" rel="noopener noreferrer" target="_blank"><b>1 x Power Supply</b></a> 
                            </li>
                            <li>
                                <a style={{padding: '0px', color: '#2c3e50'}} href="https://www.amazon.ca/Splitter-Cable-YACSEJAO-Female-Cable%EF%BC%8811-8in/dp/B0CFZ9Y3DX/ref=sr_1_1?crid=1JRZMWGZQPAQQ&dib=eyJ2IjoiMSJ9.2DnJGS4AtnXQBvCJ-byu3Zoc-hH-NoXwDOhzxWC764dKTOEsnIPpXkjQruAeNYYHfozCmHDK_C_9vFEAS3q4ip4hEcUCofRlWoqEK2KvgX4LDXg6TVTWom_zWjrzs3D2mfW5veoq5_mQDg8eJGha0IOtQdQm2AtMqkHrYCbvsQM7Y0nmwBv6jjus3tmWYlEIIBINoOQniKqYXbD4UgJoSASlFhI4RmMjRiKObMPDgVYHz4i-iFTjTfxc97pOKXSgUobYNDoEKpNjt65ShTnwiQ6PH3B2y6WgH0npZis1ILc._i_qMWZeTj8hZ9d3RQezfSxhzsdEFsrUIR0DrLX8uok&dib_tag=se&keywords=barrel+to+pc+fan+connector&qid=1719866416&sprefix=barrel+to+pc+fan+connector%2Caps%2C111&sr=8-1" rel="noopener noreferrer" target="_blank"><b>1 x DC to 3/4 Pin PC Fan Y Splitter Cable</b></a> 
                            </li>
                            <li>
                                <a style={{padding: '0px', color: '#2c3e50'}} href="https://www.amazon.ca/YMWALK-Splitter-Adapter-Converter-Extension/dp/B087ZPFKRF/ref=sxin_15_pa_sp_search_thematic_sspa?content-id=amzn1.sym.ea4b7f00-c440-4d65-bd3f-caa76cb13654%3Aamzn1.sym.ea4b7f00-c440-4d65-bd3f-caa76cb13654&crid=QWIHDI0CU1ZC&cv_ct_cx=1+to+3+pwm+fan+splitter&dib=eyJ2IjoiMSJ9.e5EYq4PISGc10YFMQZNhlUqOu42mzCsyjifDjxUYPIHd8zaeDFifG6llAr2URQrdYYEAQHNxQ5VMKlYQymMIoQ.2baUL11Q9y01p9YkWWdlpD2knA16c197XX5ByybVxWk&dib_tag=se&keywords=1+to+3+pwm+fan+splitter&pd_rd_i=B087ZPFKRF&pd_rd_r=b7b5a6e7-0322-455f-b1bc-9fa8cd35242d&pd_rd_w=SaSvV&pd_rd_wg=4GtvC&pf_rd_p=ea4b7f00-c440-4d65-bd3f-caa76cb13654&pf_rd_r=N3DESZ3JKD01MPC4NE90&qid=1719866492&sbo=RZvfv%2F%2FHxDF%2BO5021pAnSA%3D%3D&sprefix=1+to+3+pwm+fan+splitte%2Caps%2C103&sr=1-4-acb80629-ce74-4cc5-9423-11e8801573fb-spons&sp_csd=d2lkZ2V0TmFtZT1zcF9zZWFyY2hfdGhlbWF0aWM&psc=1" rel="noopener noreferrer" target="_blank"><b>2 x PWM Fan Splitter Cable</b></a> 
                            </li>
                            <li>
                                <a style={{padding: '0px', color: '#2c3e50'}} href="https://www.amazon.ca/Adhesive-2-Neoprene-Weather-Stripping-Insulation/dp/B08HV8QLSZ/ref=sr_1_4?crid=24C724JVZNXZY&dib=eyJ2IjoiMSJ9.SnRolH7ndcLHDHW8SoGf27Q4DoeVsgn5vNY_xyzxGn-aEsrZd6uh-paWdGaMZ7fybA9h36RtmC25tOxbwYlK_larQAYditcwAcxfP_M89kXVYwtrfrtiulpr2VqgNPM2oxETNpnZHCc9X2G2aWNrkELtIyAJe961WHGGR3fy9Xu0lT45GSWe8To1e1mzTZ83KmkpWGEtL-Tb4nA4Zeo15Wq2apJKzeEfGjDkTQLLF7DcWHd32d6MRCGhHdCng-vbJvMcO2KqUWXSwXk1v0NxLir65YDqzb7hpUvSDKJCmUw.U_yWFCEd1QZG8sXK4EexgZs8-TMaa3_No6hvjRFKb2Q&dib_tag=se&keywords=1%2F8+Inch+Thick+Weather+Stripping+Foam&qid=1719866691&s=industrial&sprefix=1%2F8+inch+thick+weather+stripping+foam%2Cindustrial%2C90&sr=1-4" rel="noopener noreferrer" target="_blank"><b>Foam Padding for inner edges on each side</b></a> 
                            </li>  
                        </ol>
                    </p>
                    <p style = {{paddingBottom: 0, textAlign: 'justify'}}>
                    ---    
                    </p>
                    <p style = {{paddingBottom: 0, textAlign: 'justify'}}>
                        The light-up PC Fan Air Purifier is inspired by several designs developed by engineers across the globe. This <a style={{padding: '0px', color: '#2c3e50'}} href="https://www.cleanairkits.com/products/the-healthy-glow-international" rel="noopener noreferrer" target="_blank"><b>configuration</b></a> of fans and filters may achieve 5 air exchanges per hour in a 510 sqft room at approximately 38 db. The required materials and instructions are included below. This configuration is more cost-effective if you are building more than one as some of the components must be purchased in bulk quantities. Check out itsairborne.com for more configuration ideas!
                    </p>
                    <p style = {{marginBottom: 10}}>
                        <b>Materials List (5 or 7-fan design)</b>:
                        <ol style={{listStylePosition: 'outside', marginLeft: 0, paddingLeft: '20px', paddingTop: 0, marginTop: 0}}>
                            <li>
                                Top/Bottom/Left/Right Side Piece Templates (in mm): If you have access to a laser cutter, you can cut these from acrylic, hardboard, and other available materials. If you don't have access to a laser cutter, corrugated plastic can be cut using the template dimensions with other tools.
                                <ul>
                                    <li>
                                        <a style={{padding: '0px', color: '#2c3e50'}} href = {old_top} rel="noopener noreferrer" target = "_blank"><b>Download Top Piece AutoCAD Drawing</b></a>
                                    </li>
                                    <li>
                                        <a style={{padding: '0px', color: '#2c3e50'}} href = {bottom} rel="noopener noreferrer" target = "_blank"><b>Download Bottom Piece AutoCAD Drawing</b></a>
                                    </li>
                                    <li>
                                        <a style={{padding: '0px', color: '#2c3e50'}} href = {left} rel="noopener noreferrer" target = "_blank"><b>Download Left Piece AutoCAD Drawing</b></a>
                                    </li>
                                    <li>
                                        <a style={{padding: '0px', color: '#2c3e50'}} href = {right} rel="noopener noreferrer" target = "_blank"><b>Download Right Piece AutoCAD Drawing</b></a>
                                    </li>
                                    <li>
                                        <a style={{padding: '0px', color: '#2c3e50'}} href = {old_left} rel="noopener noreferrer" target = "_blank"><b>Alternative Left Piece AutoCAD Drawing - for a 5-fan design</b></a>
                                    </li>
                                    <li>
                                        <a style={{padding: '0px', color: '#2c3e50'}} href = {old_right} rel="noopener noreferrer" target = "_blank"><b>Alternative Right Piece AutoCAD Drawing - for a 5-fan design</b></a>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <a style={{padding: '0px', color: '#2c3e50'}} href="https://lian-li.com/product/st120/" rel="noopener noreferrer" target="_blank"><b>4 x Lian Li ST120 Fans</b></a>
                            </li>
                            <li>
                                <a style={{padding: '0px', color: '#2c3e50'}} href="https://www.arctic.de/en/P12/ACFAN00118A" rel="noopener noreferrer" target="_blank"><b>3 x Arctic P12 Fans</b></a>
                            </li>
                            <li>
                                28 x Grill/Fan Fasteners: There are several options available depending on the look that you want. The Weatherstrip Fasteners are more expensive because you have to purchase them in bulk.
                                <ul>
                                    <li>
                                        <a style={{padding: '0px', color: '#2c3e50'}} href="https://shop.wurth.ca/fasteners/automotive-fasteners-body-clips/ford-fasteners/W-STRIP-FASTENR-BLK-GM-FORD-AM/502.11612/" rel="noopener noreferrer" target="_blank"><b>Nylon Weather Strip Fasteners</b></a> 
                                    </li>
                                    <li>
                                    <a style={{padding: '0px', color: '#2c3e50'}} href="https://www.digikey.ca/en/products/detail/essentra-components/167205000002/7794368" rel="noopener noreferrer" target="_blank"><b>Nylon Fir Tree Rivet</b></a> 
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <a style={{padding: '0px', color: '#2c3e50'}} href="https://www.digikey.ca/en/products/detail/qualtek/08130/678420" rel="noopener noreferrer" target="_blank"><b>7 x Protective Fan Grill</b></a> 
                            </li>
                            <li>
                                <a style={{padding: '0px', color: '#2c3e50'}} href="https://www.digikey.ca/en/products/detail/3m/EA01-4/16499590" rel="noopener noreferrer" target="_blank"><b>2 x 16x25x1 Inch MERV-13 Filters</b></a>
                            </li>
                            <li>
                                <a style={{padding: '0px', color: '#2c3e50'}} href="https://www.displaysandholders.com/l-connector-6inch-18.html" rel="noopener noreferrer" target="_blank"><b>4 x Corner Connector Pieces</b></a>: You will need to ask for these to be cut to a 7 inch length. While this component works well and creates a sturdy frame, I'm looking into simpler options for future designs.
                            </li>
                            <li>
                                <a style={{padding: '0px', color: '#2c3e50'}} href="https://www.digikey.ca/en/products/detail/sparkfun-electronics/TOL-15664/10650708" rel="noopener noreferrer" target="_blank"><b>1 x Power Supply</b></a> 
                            </li>
                            <li>
                                <a style={{padding: '0px', color: '#2c3e50'}} href="https://www.amazon.ca/Monoprice-108800-6-Inch-Splitter-Connector/dp/B0087FMTMM/ref=sr_1_38?crid=3LHNCF2W8XJC3&keywords=ATX+4-pin+to+dual+serial&qid=1678888388&sprefix=atx+4-pin+to+dual+serial%2Caps%2C171&sr=8-38" rel="noopener noreferrer" target="_blank"><b>1 x Dual Serial to Power Cable</b></a> 
                            </li>
                            <li>
                                <a style={{padding: '0px', color: '#2c3e50'}} href="https://www.amazon.ca/Splitter-Cable-Computer-Extension-Converter/dp/B0862129VL/ref=pd_lpo_3?pd_rd_w=PM9cc&content-id=amzn1.sym.bc8b374c-8130-4c45-bf24-4fcc0d96f4d6&pf_rd_p=bc8b374c-8130-4c45-bf24-4fcc0d96f4d6&pf_rd_r=56Q340QYVD5NPPNC6JYP&pd_rd_wg=nm7X4&pd_rd_r=a041474c-eabf-4f35-a300-d55e503eadac&pd_rd_i=B0862129VL&th=1" rel="noopener noreferrer" target="_blank"><b>1 x PWM Fan Splitter Cable</b></a> 
                            </li>
                            <li>
                                <a style={{padding: '0px', color: '#2c3e50'}} href="https://www.digikey.ca/en/products/detail/3m/SJ6506/3866122" rel="noopener noreferrer" target="_blank"><b>2 x Rubber Bottom Pads</b></a> 
                            </li>
                            <li>
                                Foam Insulation Tape: wait to purchase this until you know what thickness you need as this may depend on the thickness of your side pieces and the size of your filter.
                                <ul>
                                    <li>
                                        <a style={{padding: '0px', color: '#2c3e50'}} href="https://www.amazon.ca/Insulation-Stripping-Soundproofing-Weatherstrip-Conditioning/dp/B086YSHPN9/ref=sr_1_44?crid=3GEOPDDAKVXV&keywords=peel%2Band%2Bstick%2Bfoam%2Bstrip&qid=1678890515&sprefix=peel%2Band%2Bstick%2Bfoam%2Bstrip%2Caps%2C168&sr=8-44&th=1" rel="noopener noreferrer" target="_blank"><b>If using 4mm side pieces (e.g., corrugated plastic): 64 Inches of Thick Weather Stripping Foam (1/2 Inch Wide, 1/4 Inch Thick), and you may only need it on the left and right sides of the box if using a 3M filter</b></a> 
                                    </li>
                                    <li>
                                    <a style={{padding: '0px', color: '#2c3e50'}} href="https://www.digikey.ca/en/products/detail/essentra-components/167205000002/7794368" rel="noopener noreferrer" target="_blank"><b>If using 3mm side pieces (e.g., acrylic, harboard, etc.): 164 Inches of Thin Weather Stripping Foam (1/2 Inch Wide, 1/16 Inch Thick) and you will likely need it on all sides of the box if using a 3M filter</b></a> 
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <a style={{padding: '0px', color: '#2c3e50'}} href="https://www.amazon.ca/s?k=gaffers+tape" rel="noopener noreferrer" target="_blank"><b>164 Inches of 2 Inch Thick Tape (Optional: to seal any large gaps)</b></a>
                            </li>
                        </ol>
                    </p>
                    <p style = {{marginBottom: 0}}>
                        <b>Instructions</b>:
                            <ul style={{listStylePosition: 'outside', marginLeft: 0, paddingLeft: '20px', paddingTop: 0, marginTop: 0}}>
                            <li>
                                PDF Slide Deck: <a style={{padding: '0px', color: '#2c3e50'}} href = {assembly} rel="noopener noreferrer" target = "_blank"><b>Download PDF</b></a>, or
                            </li>
                            <li>
                                Assembly Video:
                                <iframe 
                                    width="560" 
                                    height="315" 
                                    src="https://www.youtube.com/embed/wS0LK_LiqmE?si=tQQnqY_wjBSf_kU2" 
                                    title="YouTube video player" 
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                                    allowFullScreen>
                                </iframe>
                            </li>
                        </ul>

                    </p>
                    
                    <p style={{textAlign: 'center'}}>
                        {/*<a style={{padding: '10px', color: '#2c3e50'}} href="https://uwaterloo.ca/news/running-vaccine-clinic-theres-app" rel="noopener noreferrer" target="_blank"><b>uWaterloo News Article</b></a>
                        <a style={{padding: '10px', color: '#2c3e50'}} href="https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwiAsLGHnYjtAhWPmeAKHUqWAEYQtwIwAXoECAEQAg&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3Do3uPSR0nkOc&usg=AOvVaw1wsVOjXbqsQZv7dneAHUMd" rel="noopener noreferrer" target="_blank"><b>Presentation</b></a>
                        <a style={{padding: '10px', color: '#2c3e50'}} href = {Test} rel="noopener noreferrer" target = "_blank"><b>Privacy Policy & Terms of Use</b></a>*/}
                    </p>
                    
                    <p style = {{paddingTop: 0}}>
                        {/*<b>Media</b>:
                        <ul style={{listStylePosition: 'outside', marginLeft: 0, paddingLeft: '20px', paddingTop: 0, marginTop: 0}}>
                            <li>
                                University of Waterloo Home
                            </li>
                        </ul>*/}
                    </p>
                </CardText>
            )
        }
        
    }

    first = () => {
        if (this.state.card1 === 'Learn More') {
            return(
                <CardText style={{textAlign: 'center'}}>
                    Google Dialogflow | RASA | React Native | Firebase
                </CardText>
            )
        }
        else {
            return(
                <CardText style={{textAlign: 'left', color: '#000'}}>
                    <p style = {{marginBottom: 10}}>
                        <b>Design Process Type</b>: User-Centered
                    </p>
                    <p style = {{marginBottom: 0}}>
                        <b>Supervisors</b>: 
                        <ul style={{listStylePosition: 'outside', marginLeft: 0, paddingLeft: '20px', paddingTop: 0, marginTop: 0}}>
                            <li>
                                Dr. Catherine Burns
                            </li>
                            <li>
                                Dr. Kate Mercer
                            </li>
                        </ul>
                    </p>
                    <p style = {{marginBottom: 10}}>
                        <b>Project Type</b>: Masters Thesis
                    </p>
                    <p>
                        <b>Project Status</b>: Paused
                    </p>
                    <p style={{textAlign: 'center'}}>
                        <a style={{padding: '10px', color: '#2c3e50'}} href="https://www.youtube.com/watch?v=kdIvUYyuqX0" rel="noopener noreferrer" target="_blank"><b>GRADflix Video</b></a>
                    </p>
                    <p style = {{paddingBottom: 10, textAlign: 'justify'}}>
                        Virtual Assistants have been considered the future method for interacting with information systems. They have the potential to provide a more natural way to interact with an interface through human language, and they are becoming a more ubiquitous interaction mode in our society. With the rise in adoption of Virtual Assistants such as Alexa, Siri and Google Assistant in the home, there is therefore a need to better understand how humans interact with such systems in various contexts to improve this interaction.
                    </p>
                    <p style = {{paddingBottom: 0, textAlign: 'justify'}}>
                        In the context of home care, significant research has been done to understand the human factor challenges that caregivers face when providing care. With recent advances in medical technology more children who are born with medical complexities are living longer, and the geriatric population is increasingly choosing to age-in-place. With this shift in our healthcare system, there is a growing need for medical professionals to be able to provide care services in the home, and an increasing need for families to provide care and assume the required responsibilities of a primary caregiver. Despite this shift, to the best of the authors' knowledge there are limited substantial technologies designed to support caregivers in the context of home care. The design of information systems in the form of graphical user interfaces (GUIs) has potential to provide support for caregivers in the context of home care, however recent studies have cautioned against their use in home care. There are concerns that the current state of interaction with GUIs creates a significant burden for caregivers to use in the home which outweighs their perceived value, and therefore prevents their adoption in the home care context. A conversational user interface (CUI) has the potential to reduce the burden of family caregivers and hired caregivers interacting with such information systems. As a first step towards designing a CUI for family caregivers and hired caregivers, it is therefore critical to understand their work domain and perception on the use of a Virtual Assistant through this research, to inform design and development.
                    </p>
                    <p style = {{marginBottom: 10}}>
                        <b>Methods/Frameworks</b>: 
                        <ul style={{listStylePosition: 'outside', marginLeft: 0, paddingLeft: '20px', paddingTop: 0, marginTop: 0}}>
                            <li>
                                Qualitative Analysis - Contextual Inquiry
                            </li>
                            <li>
                                Quantitative Analysis - Survey Design (Qualtrics)
                            </li>
                            <li>
                                Cognitive Work Analysis - Control Task Analysis
                            </li>
                            <li>
                                System Usability Score
                            </li>
                            <li>
                                Technology Acceptance Model
                            </li>
                        </ul>
                    </p>
                    <p style = {{paddingTop: 10}}>
                        <b>Awards/Recognitions</b>:
                        <ul style={{listStylePosition: 'outside', marginLeft: 0, paddingLeft: '20px', paddingTop: 0, marginTop: 0}}>
                            <li>
                                2021 - University of Waterloo Graduate Studies and Postdoctoral Affair GRADflix - Finalist
                            </li>
                        </ul>
                    </p>
                </CardText>
            )
        }
    }

    second = () => {
        if (this.state.card2 === 'Learn More') {
            return(
                <CardText style={{textAlign: 'center'}}>
                    React | Material Design Lite | Visual Studio Code | GitHub | Photoshop
                </CardText>
            )
        }
        else {
            return(
                <CardText style={{textAlign: 'left', color: '#000'}}>
                    <p style = {{marginBottom: 10}}>
                        <b>Design Process Type</b>: Iterative
                    </p>
                    <p style = {{marginBottom: 10}}>
                        <b>Project Type</b>: Personal Project
                    </p>
                    <p style = {{marginBottom: 10}}>
                        <b>Project Status</b>: Ongoing
                    </p>
                    <motion.p style = {{paddingBottom: 10, textAlign: 'justify'}}>
                        This website was created to showcase projects and work experience related to design and development of digital technology. One of the most important considerations made in the design of this website was its ability to adapt to a range of aspect ratios to ensure an optimal viewing experience on any device.
                    </motion.p>
                </CardText>
            )
        }
    }

    third = () => {
        if (this.state.card3 === 'Learn More') {
            return(
                <CardText style={{textAlign: 'center'}}>
                    SwiftUI | Xcode | GitHub
                </CardText>
            )
        }
        else {
            return(
                <CardText style={{textAlign: 'left', color: '#000'}}>
                    <p style = {{marginBottom: 10}}>
                        <b>Design Process Type</b>: Iterative
                    </p>
                    <p style = {{marginBottom: 10}}>
                        <b>Instructor</b>: Dr. Oliver Schneider
                    </p>
                    <p style = {{marginBottom: 10}}>
                        <b>Collaborators</b>: 
                        <ul style={{listStylePosition: 'outside', marginLeft: 0, paddingLeft: '20px', paddingTop: 0, marginTop: 0}}>
                            <li>
                                Olamide Olatoye - MASc Candidate
                            </li>
                            <li>
                                Pelisha Samithamby - MASc Candidate
                            </li>
                        </ul>
                    </p>
                    <p>
                        <b>Project Status</b>: Complete
                    </p>
                    <p style = {{paddingBottom: 0, textAlign: 'justify'}}>
                        Existing fitness trackers offer a variety of performance metric capabilities, consequently supporting result oriented behaviour. While external rewards can effectively improve an individual's performance, motivation is often short term, leading to reduced engagement not only with the fitness tracker but with the activity as well. We explored the intersection of intrinsic motivation and haptics to identify if a fitness application for wearables can be designed to instriniscly motivate users during exercise. We first evaluate self-determination theory and identify aspects of intrinsic motivation that are vital in developing long term healthy habits. We then propose haptic functionality that can support intrinsic motivation. Our main contribution is the development of a novel WatchOS application, the Fitness HApp. Utlizing haptic technology we designed a fitness application by following an iterative design process to positively influence intrinsic motivation during aerobic exercise. The primary functionality of the application is communicating heart rate metrics through tactile stimuli.</p>
                        <p style = {{marginBottom: 10}}>
                        <b>Methods/Frameworks</b>: 
                        <ul style={{listStylePosition: 'outside', marginLeft: 0, paddingLeft: '20px', paddingTop: 0, marginTop: 0}}>
                            <li>
                                Self-Determination Theory
                            </li>
                            <li>
                                Behaviour Change Techniques
                            </li>
                        </ul>
                    </p>
                
                </CardText>
            )
        }
    }

    fourth = () => {
        if (this.state.card4 === 'Learn More') {
            return(
                <CardText style={{textAlign: 'center'}}>
                   Proto.io | React Native | Expo | Firebase | JavaScript | GitHub
                </CardText>
            )
        }
        else {
            return(
                <CardText style={{textAlign: 'left', color: '#000'}}>
                    <p style = {{marginBottom: 10}}>
                        <b>Design Process Type</b>: Iterative & User-Centered
                    </p>
                    <p style = {{marginBottom: 0}}>
                        <b>Supervisors</b>:
                            <ul style={{listStylePosition: 'outside', marginLeft: 0, paddingLeft: '20px', paddingTop: 0, marginTop: 0}}>
                            <li>
                                Dr. Catherine Burns 
                            </li>
                            <li>
                                Dr. Kate Mercer
                            </li>
                        </ul>
                    </p>
                    <p>
                    <b>Collaborators</b>: 
                        <ul style={{listStylePosition: 'outside', marginLeft: 0, paddingLeft: '20px', paddingTop: 0, marginTop: 0}}>
                            <li>
                                Alexandra Leggett - BASc Biomedical Engineering
                            </li>
                            <li>
                                Corinna Smith - BASc Biomedical Engineering
                            </li>
                            <li>
                                Lynnea Tan - BASc Biomedical Engineering
                            </li>
                        </ul>
                    </p>
                    <p>
                        <b>Project Status</b>: Paused
                    </p>
                    <p style={{textAlign: 'center'}}>
                        <a style={{padding: '10px', color: '#2c3e50'}} href="https://journals.sagepub.com/doi/pdf/10.1177/2327857920091051" rel="noopener noreferrer" target="_blank"><b>Publication</b></a>
                        <a style={{padding: '10px', color: '#2c3e50'}} href="https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwiAsLGHnYjtAhWPmeAKHUqWAEYQtwIwAXoECAEQAg&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3Do3uPSR0nkOc&usg=AOvVaw1wsVOjXbqsQZv7dneAHUMd" rel="noopener noreferrer" target="_blank"><b>Presentation</b></a>
                    </p>
                    <p style = {{paddingBottom: 0, textAlign: 'justify'}}>
                        Stellar Care is a mobile application that was designed and developed to improve the continuity of care for families of children with medical complexities (CMCs). Communication and coordination between caregivers in a child's healthcare team is critical for maintaining situational awareness in the home and reducing the occurrence of medical errors. Current tools that are available for caregivers to communicate health information in the home include paper binders and computer applications. The human factor challenges associated with these tools include the communication of a child's health status and baseline as well as the ability for caregivers to efficiently access health information. The objectives of this work were to identify the human factor challenges that families and nurses of CMC experience in the home, and then design and evaluate the performance and usability of a mobile application, Stellar Care. Stellar Care is currently in development.
                    </p>
                    <p style = {{marginBottom: 10}}>
                        <b>Methods/Frameworks</b>: 
                        <ul style={{listStylePosition: 'outside', marginLeft: 0, paddingLeft: '20px', paddingTop: 0, marginTop: 0}}>
                            <li>
                                Qualitative Analysis - Contextual Inquiry
                            </li>
                            <li>
                                Quantitative Analysis - Survey Design (Google Forms)
                            </li>
                            <li>
                                Engineering Requirements, Constraints, Functions
                            </li>
                            <li>
                                Quality Function Deployment
                            </li>
                            <li>
                                User Personas
                            </li>
                            <li>
                                Concept Generation (modified 6-3-5 method)
                            </li>
                            <li>
                                Technology Acceptance Model
                            </li>
                            <li>
                                System Usability Score
                            </li>
                            <li>
                                Task Analysis
                            </li>
                        </ul>
                    </p>
                    
                    <p style = {{paddingTop: 10}}>
                        <b>Awards/Recognitions</b>:
                        <ul style={{listStylePosition: 'outside', marginLeft: 0, paddingLeft: '20px', paddingTop: 0, marginTop: 0}}>
                            <li>
                                2020 - John's Hopkins Healthcare Design Competition - Finalist
                            </li>
                            <li>
                                2019 - Best Capstone Design Project for Biomedical Engineering
                            </li>
                            <li>
                                2019 - Norman Esch Entrepreneurship Award
                            </li>
                            <li>
                                2019 - Velocity 5K Fund - Finalist
                            </li>
                        </ul>
                    </p>
                </CardText>
            )
        }
        
    }

    fifth = () => {
        if (this.state.card5 === 'Learn More') {
            return(
                <CardText style={{textAlign: 'center'}}>
                   React Native | Expo | Firebase | JavaScript | GitHub
                </CardText>
            )
        }
        else {
            return(
                <CardText style={{textAlign: 'left', color: '#000'}}>
                    <p style = {{marginBottom: 10}}>
                        <b>Design Process Type</b>: Iterative & User-Centered & Participatory
                    </p>
                    <p style = {{marginBottom: 0}}>
                        <b>Supervisors</b>:
                            <ul style={{listStylePosition: 'outside', marginLeft: 0, paddingLeft: '20px', paddingTop: 0, marginTop: 0}}>
                            <li>
                                Dr. Kelly Grindrod
                            </li>
                            <li>
                                Dr. Catherine Burns
                            </li>
                        </ul>
                        <b>Collaborators</b>:
                            <ul style={{listStylePosition: 'outside', marginLeft: 0, paddingLeft: '20px', paddingTop: 0, marginTop: 0}}>
                            <li>
                                Dr. Moses Tetui
                            </li>
                        </ul>
                    </p>
                    
                    <p>
                        <b>Project Status</b>: Paused
                    </p>
                    <p style={{textAlign: 'center'}}>
                        <a style={{padding: '10px', color: '#2c3e50'}} href="https://uwaterloo.ca/news/running-vaccine-clinic-theres-app" rel="noopener noreferrer" target="_blank"><b>uWaterloo News Article</b></a>
                        {/*<a style={{padding: '10px', color: '#2c3e50'}} href="https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwiAsLGHnYjtAhWPmeAKHUqWAEYQtwIwAXoECAEQAg&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3Do3uPSR0nkOc&usg=AOvVaw1wsVOjXbqsQZv7dneAHUMd" rel="noopener noreferrer" target="_blank"><b>Presentation</b></a>*/}
                        <a style={{padding: '10px', color: '#2c3e50'}} href = {Test} rel="noopener noreferrer" target = "_blank"><b>Privacy Policy & Terms of Use</b></a>
                    </p>
                    <p style = {{paddingBottom: 0, textAlign: 'justify'}}>
                        The mass vaccination clinic mobile app was designed and developed to support client/dose tracking and communication across clinic teams, in order to improve situational awareness about available doses and support end-of-day doses decision-making. If you would like to access the app for a vaccine clinic that you are running, please contact Ryan Tennant.
                    </p>
                    <p style = {{marginBottom: 10}}>
                        <b>Methods/Frameworks</b>: 
                        <ul style={{listStylePosition: 'outside', marginLeft: 0, paddingLeft: '20px', paddingTop: 0, marginTop: 0}}>
                            <li>
                                Qualitative Analysis - Contextual Inquiry/Contextual Design
                            </li>
                            <li>
                                Quantitative Analysis - System Usability Scale, Situation Awareness Rating Technique
                            </li>
                            <li>
                                Workflow Analysis
                            </li>
                        </ul>
                    </p>
                    
                    <p style = {{paddingTop: 10}}>
                        {/*<b>Media</b>:
                        <ul style={{listStylePosition: 'outside', marginLeft: 0, paddingLeft: '20px', paddingTop: 0, marginTop: 0}}>
                            <li>
                                University of Waterloo Home
                            </li>
                        </ul>*/}
                    </p>
                </CardText>
            )
        }
        
    }

    sixth = () => {
        if (this.state.card6 === 'Learn More') {
            return(
                <CardText style={{textAlign: 'center'}}>
                   React Native | Expo | SQLite | JavaScript | GitHub
                </CardText>
            )
        }
        else {
            return(
                <CardText style={{textAlign: 'left', color: '#000'}}>
                    <p style = {{marginBottom: 10}}>
                        <b>Design Process Type</b>: Iterative & User-Centered & Participatory
                    </p>
                    <p style = {{marginBottom: 0}}>
                        <b>Collaborators</b>:
                            <ul style={{listStylePosition: 'outside', marginLeft: 0, paddingLeft: '20px', paddingTop: 0, marginTop: 0}}>
                            <li>
                                Biomechanics and Injury Prevention Lab
                            </li>
                        </ul>
                    </p>
                    
                    <p>
                        <b>Project Status</b>: Complete
                    </p>
                    <p style={{textAlign: 'center'}}>
                        <a href="https://apps.apple.com/us/app/evas/id6447213570?itsct=apps_box_badge&amp;itscg=30200" target="_blank" rel="noopener noreferrer"><img src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1680912000" alt="Download on the App Store" /></a>
                    </p>
                    {/*<p style={{textAlign: 'center'}}>
                        <img src="https://tools-qr-production.s3.amazonaws.com/output/apple-toolbox/41e4f660092f6e9504bb31241361f90b/53be9c15ed61b07c0a3544980cf96928.png" style={{height: 200}}/>
                    </p>*/}
                    <p style={{textAlign: 'center'}}>
                        {/*<a style={{padding: '10px', color: '#2c3e50'}} href="https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwiAsLGHnYjtAhWPmeAKHUqWAEYQtwIwAXoECAEQAg&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3Do3uPSR0nkOc&usg=AOvVaw1wsVOjXbqsQZv7dneAHUMd" rel="noopener noreferrer" target="_blank"><b>Presentation</b></a>*/}
                        <a style={{padding: '10px', color: '#2c3e50'}} href = {eVASPolicy} rel="noopener noreferrer" target = "_blank"><b>Privacy Policy</b></a>
                    </p>
                    <p style={{textAlign: 'center'}}>
                        {/*<a style={{padding: '10px', color: '#2c3e50'}} href="https://uwaterloo.ca/news/running-vaccine-clinic-theres-app" rel="noopener noreferrer" target="_blank"><b>uWaterloo News Article</b></a>*/}
                        {/*<a style={{padding: '10px', color: '#2c3e50'}} href="https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwiAsLGHnYjtAhWPmeAKHUqWAEYQtwIwAXoECAEQAg&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3Do3uPSR0nkOc&usg=AOvVaw1wsVOjXbqsQZv7dneAHUMd" rel="noopener noreferrer" target="_blank"><b>Presentation</b></a>*/}
                        {/*<a style={{padding: '10px', color: '#2c3e50'}} href = {Test} rel="noopener noreferrer" target = "_blank"><b>Privacy Policy & Terms of Use</b></a>*/}
                    </p>
                    <p style = {{paddingBottom: 0, textAlign: 'justify'}}>
                        The Electronic Visual Analog Scale (eVAS) is an ad-free iPad app that allows users to digitally track visual analog scores using 10 cm slider channels with various customizations and export saved data in tables. It can be used as a pain scale tracker or for any other application that requires user input tracking on a 10 cm visual analog scale.
                    </p>

                    <p style = {{marginBottom: 0}}>
                        <b>Donate</b>:
                        <p style ={{textAlign: 'justify'}}>
                            I am pleased to provide eVAS to you free of charge. If you enjoy using eVAS, please consider donating to support eVAS maintenance, future development, and upgrades. Donations of any size are appreciated.
                        </p>
                        <p style={{textAlign: 'center'}}>
                            Thanks for your support!
                        </p>
                        <p>
                        
                        <form style={{textAlign: 'center'}} action="https://www.paypal.com/donate" method="post" target="_blank">
                            <input type="hidden" name="business" value="22WMU69NT7DKJ" />
                            <input type="hidden" name="no_recurring" value="1" />
                            <input type="hidden" name="item_name" value="Please consider donating to support eVAS. Donations of any size are appreciated. Thank you!" />
                            <input type="hidden" name="currency_code" value="CAD" />
                            <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
                            <img alt="" border="0" src="https://www.paypal.com/en_CA/i/scr/pixel.gif" width="1" height="1"/>
                        </form>

                        </p>
                    </p>
                    
                    {/*}<p>
                        We provide users with various customizations in eVAS. Users can customize their collection settings by:
                    </p>

                    <p style = {{marginBottom: 10}}>
                        <ul style={{listStylePosition: 'outside', marginLeft: 0, paddingLeft: '20px', paddingTop: 0, marginTop: 0}}>
                            <li>
                                choosing one of three default images or uploading an image from their device to be stored locally,
                            </li>
                            <li>
                                specifying a session identification label,
                            </li>
                            <li>
                                setting left and right anchor label text,
                            </li>
                            <li>
                                selecting among 1-22 collection channels to record visual analog scores*,
                            </li>
                            <li>
                                setting a recurring alert reminder within the app, and
                            </li>
                            <li>
                                exporting their data in .CSV or .XLSX format via e-mail or other file sharing methods available on their device.
                            </li>
                        </ul>
                    </p>
                    <p>
                        Find the complete instructions and other information on how to use eVAS under the info icon on the Collection Settings screen.
                    </p>

                    <p>
                        *The slider length is 10 cm — output scores range from 0 to 1 for each channel with a precision of 6 digits. The 10 cm visual analog scale and threshold values for clinically relevant pain have been previously evaluated in clinical research applications with humans, such as detecting clinically relevant low back pain [1,2]. The design of eVAS employs the 10 cm scale that has been validated in peer-reviewed literature [1-5]. Its design is further informed by current research validating the reliability and accuracy of clinically relevant pain using visual analog scales through touchscreen devices [3-5].
                    </p>
                    
                    <p style={{textAlign: 'center'}}>
                        <b>DISCLAIMER: EVAS DOES NOT PROVIDE MEDICAL ADVICE</b>
                    </p>

                    <p>
                        The Contents of the App is for data collection and informational purposes only. No material on this app is intended to be a substitute for professional medical advice, diagnosis or treatment. Always seek the advice of your physician or other qualified health care provider with any questions you may have regarding a medical condition or treatment and before undertaking a new health care regimen or making any medical decisions, and never disregard professional medical advice or delay in seeking it because of your use of eVAS.
                    </p>

                    <p>
                    <b>References:</b>
                    
                    <ol style={{listStylePosition: 'outside', marginLeft: 0, paddingLeft: '20px', paddingTop: 0, marginTop: 0}}>
                        <li>
                            <a style={{color: '#2c3e50'}} href = 'https://pubmed.ncbi.nlm.nih.gov/24384659/' rel="noopener noreferrer" target = "_blank"><b>https://pubmed.ncbi.nlm.nih.gov/24384659/</b></a>
                        </li>
                        <li>
                            <a style={{color: '#2c3e50'}} href = 'https://pubmed.ncbi.nlm.nih.gov/12592542/' rel="noopener noreferrer" target = "_blank"><b>https://pubmed.ncbi.nlm.nih.gov/12592542/</b></a>
                        </li>
                        <li>
                            <a style={{color: '#2c3e50'}} href = 'https://pubmed.ncbi.nlm.nih.gov/26769149/' rel="noopener noreferrer" target = "_blank"><b>https://pubmed.ncbi.nlm.nih.gov/26769149/</b></a>
                        </li>
                        <li>
                            <a style={{color: '#2c3e50'}} href = 'https://pubmed.ncbi.nlm.nih.gov/30211382/' rel="noopener noreferrer" target = "_blank"><b>https://pubmed.ncbi.nlm.nih.gov/30211382/</b></a>
                        </li>
                        <li>
                            <a style={{color: '#2c3e50'}} href = 'https://pubmed.ncbi.nlm.nih.gov/12237213/' rel="noopener noreferrer" target = "_blank"><b>https://pubmed.ncbi.nlm.nih.gov/12237213/</b></a>
                        </li>
                    </ol>
                   </p>*/}
                    
                    <p style = {{paddingTop: 10}}>
                        {/*<b>Media</b>:
                        <ul style={{listStylePosition: 'outside', marginLeft: 0, paddingLeft: '20px', paddingTop: 0, marginTop: 0}}>
                            <li>
                                University of Waterloo Home
                            </li>
                        </ul>*/}
                    </p>
                </CardText>
            )
        }
        
    }

    render() {
        return(
            <div>
                 <Grid className="projects-grid" style = {{justifyContent: 'center'}}>
                        <Cell col={5} phone={12} tablet={6}>
                            <motion.div 
                                initial={{ opacity: 0, y: 50}}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ delay: 0, duration: 1 }}
                            >
                            <Card shadow={0} style={{width: '100%', margin: 'auto', borderRadius: 20}}>
                                <CardTitle style={{color: '#000', height: '400px', background: `url(${this.state.air_purifier}) center/cover no-repeat`}}>
                                </CardTitle>
                                <CardTitle style={{color: '#fff', background: '#2c3e50', justifyContent: 'center', fontSize: 20}}> <Button onClick={this.card7_image_prev} style={{flex: 1, justifyContent: 'flex-start', color: '#fff', height: '100%'}}><i class="fa fa-arrow-left" aria-hidden="true"></i></Button>PC Fan Air Purifier<Button onClick={this.card7_image_next} style={{flex: 1, justifyContent: 'flex-end', color: '#fff', height: '100%'}}><i class="fa fa-arrow-right" aria-hidden="true"></i></Button>
                               </CardTitle>
                                {this.air_purifier()}
                                <CardActions border>
                                    <Button colored onClick={this.change_card7}>{this.state.card7}</Button>
                                </CardActions>
                            </Card>
                            </motion.div>
                        </Cell>
                        <Cell col={5} phone={12} tablet={6}>
                            <motion.div 
                                initial={{ opacity: 0, y: 50}}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ delay: 0, duration: 1 }}
                            >
                            <Card shadow={0} style={{width: '100%', margin: 'auto', borderRadius: 20}}>
                                <CardTitle style={{color: '#000', height: '400px', background: `url(${this.state.app3}) center/cover no-repeat`}}>
                                </CardTitle>
                                <CardTitle style={{color: '#fff', background: '#2c3e50', justifyContent: 'center', fontSize: 20}}> <Button onClick={this.card6_image_prev} style={{flex: 1, justifyContent: 'flex-start', color: '#fff', height: '100%'}}><i class="fa fa-arrow-left" aria-hidden="true"></i></Button>eVAS App<Button onClick={this.card6_image_next} style={{flex: 1, justifyContent: 'flex-end', color: '#fff', height: '100%'}}><i class="fa fa-arrow-right" aria-hidden="true"></i></Button>
                               </CardTitle>
                                {this.sixth()}
                                <CardActions border>
                                    <Button colored onClick={this.change_card6}>{this.state.card6}</Button>
                                </CardActions>
                            </Card>
                            </motion.div>
                        </Cell>
                        <Cell col={5} phone={12} tablet={6}>
                            <motion.div 
                                initial={{ opacity: 0, y: 50}}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ delay: 0, duration: 1 }}
                            >
                            <Card shadow={0} style={{width: '100%', margin: 'auto', borderRadius: 20}}>
                                <CardTitle style={{color: '#000', height: '400px', background: `url(${this.state.app2}) center/cover no-repeat`}}>
                                </CardTitle>
                                <CardTitle style={{color: '#fff', background: '#2c3e50', justifyContent: 'center', fontSize: 20}}> <Button onClick={this.card5_image_prev} style={{flex: 1, justifyContent: 'flex-start', color: '#fff', height: '100%'}}><i class="fa fa-arrow-left" aria-hidden="true"></i></Button>Mass Vaccination Clinic Mobile App<Button onClick={this.card5_image_next} style={{flex: 1, justifyContent: 'flex-end', color: '#fff', height: '100%'}}><i class="fa fa-arrow-right" aria-hidden="true"></i></Button>
                               </CardTitle>
                                {this.fifth()}
                                <CardActions border>
                                    <Button colored onClick={this.change_card5}>{this.state.card5}</Button>
                                </CardActions>
                            </Card>
                            </motion.div>
                        </Cell>
                        <Cell col={5} phone={12} tablet={6}>
                            <motion.div 
                                initial={{ opacity: 0, y: 50}}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ delay: 0, duration: 1 }}
                            >
                            <Card shadow={0} style={{width: '100%', margin: 'auto', borderRadius: 20}}>
                                <CardTitle style={{color: '#000', height: '400px', background: `url(${this.state.va}) center/cover no-repeat`}}>
                                </CardTitle>
                                <CardTitle style={{color: '#fff', background: '#2c3e50', justifyContent: 'center', fontSize: 20}}> <Button onClick={this.card1_image_prev} style={{flex: 1, justifyContent: 'flex-start', color: '#fff', height: '100%'}}><i class="fa fa-arrow-left" aria-hidden="true"></i></Button>Virtual Assistant for Home Care<Button onClick={this.card1_image_next} style={{flex: 1, justifyContent: 'flex-end', color: '#fff', height: '100%'}}><i class="fa fa-arrow-right" aria-hidden="true"></i></Button>
                               </CardTitle>
                                {this.first()}
                                <CardActions border>
                                    <Button colored onClick={this.change_card1}>{this.state.card1}</Button>
                                </CardActions>
                            </Card>
                            </motion.div>
                        </Cell>
                        <Cell col={5} phone={12} tablet={6}>
                            <motion.div initial={{ opacity: 0, y: 50}}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ delay: 0, duration: 1 }}>
                            <Card shadow={0} style={{width: '100%', margin: 'auto', borderRadius: 20}}>
                                <CardTitle style={{color: '#000', height: '400px', background: `url(${this.state.app}) center/cover no-repeat`}}>
                                </CardTitle>
                                <CardTitle style={{color: '#fff', background: '#2c3e50', justifyContent: 'center', fontSize: 20}}><Button onClick={this.card4_image_prev} style={{flex: 1, justifyContent: 'flex-start', color: '#fff', height: '100%'}}><i class="fa fa-arrow-left" aria-hidden="true"></i></Button>React Native Home Healthcare App<Button onClick={this.card4_image_next} style={{flex: 1, justifyContent: 'flex-end', color: '#fff', height: '100%'}}><i class="fa fa-arrow-right" aria-hidden="true"></i></Button>
                                </CardTitle>
                                {this.fourth()}
                                <CardActions border>
                                    <Button colored onClick={this.change_card4}>{this.state.card4}</Button>
                                </CardActions>
                            </Card>
                            </motion.div>
                        </Cell>
                        <Cell col={5} phone={12} tablet={6}>
                            <motion.div initial={{ opacity: 0, y: 50}}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ delay: 0, duration: 1 }}>
                            <Card shadow={0} style={{width: '100%', margin: 'auto', borderRadius: 20}}>
                                <CardTitle style={{color: '#000', height: '400px', background: `url(${this.state.watch}) center/cover no-repeat`}}>
                                </CardTitle>
                                <CardTitle style={{color: '#fff', background: '#2c3e50', justifyContent: 'center', fontSize: 20}}><Button onClick={this.card3_image_prev} style={{flex: 1, justifyContent: 'flex-start', color: '#fff'}}><i class="fa fa-arrow-left" aria-hidden="true"></i></Button>WatchOS Fitness Application<Button onClick={this.card3_image_next} style={{flex: 1, justifyContent: 'flex-end', color: '#fff', height: '100%'}}><i class="fa fa-arrow-right" aria-hidden="true"></i></Button>
                                </CardTitle>
                                {this.third()}
                                <CardActions border>
                                    <Button colored onClick={this.change_card3}>{this.state.card3}</Button>
                                </CardActions>
                            </Card>
                            </motion.div>
                        </Cell>
                        <Cell col={5} phone={12} tablet={6}>
                            <motion.div initial={{ opacity: 0, y: 50}}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ delay: 0, duration: 1 }}>
                            <Card shadow={0} style={{width: '100%', margin: 'auto', borderRadius: 20}}>
                                <CardTitle style={{color: '#000', height: '400px', background: `url(${this.state.web}) center/cover no-repeat`}}>
                                </CardTitle>
                                <CardTitle style={{color: '#fff', background: '#2c3e50', justifyContent: 'center', fontSize: 20}}><Button onClick={this.card2_image_prev} style={{flex: 1, justifyContent: 'flex-start', color: '#fff', height: '100%'}}><i class="fa fa-arrow-left" aria-hidden="true"></i></Button>Portfolio Website<Button onClick={this.card2_image_next} style={{flex: 1, justifyContent: 'flex-end', color: '#fff', height: '100%'}}><i class="fa fa-arrow-right" aria-hidden="true"></i></Button>
                                </CardTitle>
                                {this.second()}
                                <CardActions border>
                                    <Button colored onClick={this.change_card2}>{this.state.card2}</Button>
                                </CardActions>
                            </Card>
                            </motion.div>
                        </Cell>
                    </Grid>
            </div>
        )
    }
}

export default Projects;