import React, { Component } from 'react';
import './App.css';
import { Layout, Header, Navigation, Content, Footer } from 'react-mdl';
import { Link } from 'react-router-dom';
import Main from './components/main';
import ryan from './assets/R.png';

class App extends Component {
  render() {
    return (
     <div className="demo-big-content">
        <Layout fixedHeader >
            <Header className="header-color" scroll>

                    <div> 
                        <Link to="/landing">   
                        <img
                        //src="https://uwaterloo.ca/advanced-interface-design-lab/sites/ca.advanced-interface-design-lab/files/uploads/images/ryan.jpg"
                        src={ryan}
                        alt="Ryan Tennant"
                        className="logo-img"
                        />
                        </Link>
                    </div>

                    <Navigation style={{flex: 1, justifyContent: 'flex-end', textAlign: 'center'}}>
                        <Link to="/projects">Projects</Link>
                        <Link to="/resume">Resume</Link>
                        <Link to="/contact">Contact</Link>
                    
                    </Navigation>

            </Header>
            
            <Content>

                <div className="page-content" />
                <Main/>
                    <Footer className="footer-color">
                        <div>
                            <p>Copyright © 2024 Ryan Tennant</p>
                        </div>
                    </Footer>
                    
            </Content>

        </Layout>
    </div>
    )
  }
}

export default App;
